fieldset {
    .frm-lbl {
        display: flex;
        align-items: center;

        &.width-remaining {
            padding-right: 10px;
        }
    }
    .panel-area {
        font-size: 12px !important;


        .p-t-cell {
            padding:2px 0 !important;
        }
    }
    .headers {
        color: $grey;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 0.4rem;
        text-transform: uppercase;
        display: flex;

        .badge {
          font-size: 0.4rem;
          margin: auto;
        }

        > div {
            padding-right: 1rem;
        }

        .numH {
            padding-right: 0;
            text-align: right;
            justify-content: flex-end;    
        }
    }

    .headersM {
        margin-top: 1rem;
    }

    .divider-line {
        margin: 0.8rem 0;
    }

    legend {

    }
}

.summary-totals {
    border-top: 1px solid $grey-light;
    background: $grey-super-light;
    box-shadow: 6px -2px 20px 0 rgba(0,0,0,0.05);
    padding: 0 1rem;

    > * {
        padding: 1rem 0;
        min-height: 6rem;
    }

    legend {
        margin-bottom: 0.75rem;
    }
}
