/* NAVIGATION */
/* TREE - LEFT NAV */

/* MAIN CONTENT & WRAPPERS */
.ventus-wrapper {
    margin: 0;
    position: absolute;
    top: 60px;
    left: $left-panel-width;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    &.menu-hidden {
        left: 0;
    }
}

.ventus-content,
.content-scroll,
.content-hidden {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &.fd-r {
        flex-direction: row;
    }
}

.ventus-content,
.content-scroll {
    overflow: auto;
    padding: 15px;

    &.no-padding-top {
        padding-top: 0;
    }
}

.content-hidden {
    overflow: hidden;
}

/* FLEX ITEMS - FILL SPACE AS NEEDED */
fieldset div.inpt-grp {
    display: flex;
    align-items: stretch;
}

.centered {
    justify-content: space-around;
}

.grow {
    flex-grow: 1;
}

a {
    color: $blue-primary;
}

h1 {
    color: $white;
    font-family: $raleway;
    letter-spacing: 20px;
    margin: 0;
    padding: 0;

    *>span {
        color: #e0e0e0;
        display: block;
        letter-spacing: 12px;
        font-size: 10px !important;
        margin-top: 4px;
    }
}

/* TEXT */
h2,
h3,
h4,
h5 {
    color: $grey-medium-dark;
    font-weight: 500;
    margin: 0;
    padding: 0;
    font-family: $raleway;
}

h3 {}

h5 {
    letter-spacing: 2px;
    text-align: left;
}

.header h5 {
    border-bottom: 1px solid $grey-light;
    width: 100%;
    color: $grey;

    &:nth-child(n + 2) {
        margin-top: 50px;
    }
}

.header h5,
.row h5 {
    text-transform: uppercase;
}

.section-header,
legend,
h4 {
    letter-spacing: 3px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    text-transform: uppercase;
    width: 100%;
}

legend {
    color: $blue-primary;

    &.small {
        font-size: 10px;
    }
}

.section-header {
    font-family: $raleway;
    align-items: center;
    display: flex;
    font-size: 0.85rem;
}

.header-extra {
    font-size: 0.8rem;
    white-space: nowrap;

    span {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 90%;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.arrow_box {
    background: $red-primary;
    color: $white;
    min-height: 100px;
    max-width: 400px;
    min-width: 300px;
    margin: 10px auto;
    padding: 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: left;
    z-index: 9999999999;
}

.arrow_box p {
    font-size: 12px;
}

.arrow_box:after,
.arrow_box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-top-color: $red-primary;
    border-width: 10px;
    margin-left: -10px;
}

.arrow_box:before {
    border-top-color: $red-primary;
    border-width: 6px;
    margin-left: -6px;
}

/* GENERAL PANEL LAYOUT */
.panelbuttons {
    grid-area: panelbuttons;
    display: flex;
    justify-content: flex-start;
}
.panelmetrics {
    align-items: center;
    grid-area: panelmetrics;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .panelmetrics-wrapper {
        background: $white;
        border: 1px solid $grey-border;
        // border-radius: 14px;
        display: flex;
        padding: 6px 15px 5px 15px;
        justify-content: flex-end;
        width: fit-content;
    }

    h1,
    h5 {
        color: $black;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    h5 {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0 5px 0 15px;
    }
}

.panelgeneral {
    .input-div,
    .select-cell {
        align-self: stretch !important;
        background: none !important;
        border: 0 !important;
        justify-content: stretch;
        padding: 0 !important;
    }
}

.data-entry-wrapper {
    .w-55 {
        min-width: 730px;
        resize: horizontal;
    }
    .header-select {
        margin-left: 15px;
        max-height:28px;

        input {
            font-size: 15px;
            font-weight: bold;
        }

        &.alt {
            margin-left: 0;
        }
    }
    .header-btns {
        background: none !important;
        border: 0 !important;
        max-height:27px;
        padding-left: 10px;

        button {
            max-height:27px;
            min-height:0 !important;
            margin: 0 2px !important;
            span {
                font-size: 18px !important;
            }
        }
    }
    .info-block {
        font-size: 12.5px;
        margin-bottom: 25px;
        padding-bottom: 4px;

        &.excluded {
            border-bottom: 0;
            padding-bottom: 0;
        }
        .clmn-headers {
            label {
                color: $grey;
                font-family: Arial, Helvetica, sans-serif !important;
                font-size: 8px;
                font-weight: 900;
                letter-spacing: 1px;
                position: relative;
                top: 9px;
                text-align: right;
                text-transform: uppercase;
            }
        }

        .header,
        .table-header {
            background: $grey-super-light;
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            letter-spacing: 1px;
            max-height: 38px;
            padding: 8px 5px 8px 8px;
        }
        .table-header {
            height: 33px !important;

            .select-cell {
                max-height: 33px !important;
            }
        }

        .info-rows {
            grid-template-columns: minmax(0, 190px) minmax(0, 1fr);
        }
        .input-div {
            text-align: left;

            input {
                text-align: left;
            }
        }
        div[data-input-type="select"].input-div,
        div[data-input-type="input"].input-div,
        input[data-input-type="date-input"],
        button,
        .select-cell {
            max-height: 26px !important;
            min-height: 26px !important;
        }
        button {
            flex:0 !important;
        }
        .input-label {
            justify-content: flex-start;
            text-align: left;
            white-space: normal !important;
        }
    }
    input {
        font-size: 13px;
        text-align: left;
    }
    .numeric-input {
        max-width: 100px;
        input {
            text-align: right;
        }
    }
    .dollar-percent-labels {
        button {
            font-size: 11px !important;
        }
    }
    .grid-sub-wrapper {
        .grid-sub-content {
            grid-template-columns: minmax(0, 190px) minmax(0, 1fr) minmax(0, 1fr);
        }
    }
    &:hover:not(.has-normal-btn) {
        .tertiary-input {
            width: fit-content !important;
        }
    }

    &.bor-decision {
        .clmn-headers {
            border-top: 1px solid $border-grey;
            grid-column-end: span 2;
            width: 100%;

            label {
                padding: 0 8px;
                text-align: left;
                width: 100%;
            }
        }
        .table-pref {
            min-width: 200px;
        }
        .info-rows {
            grid-template-columns: minmax(0, 190px) minmax(0, 1fr);
            margin: 8px 0 6px 0;

            .input-label {
                align-items: flex-start;
                margin-left: 8px;

                i {
                    display: block;
                    font-style: italic;
                    padding-left: 20px;
                }
                &.indent {
                    font-style: italic;
                    padding-left: 20px;
                }
            }
            .input-div,
            .input-static {
                margin-right: 8px;

                &.textarea {
                    min-height: 200px;
                }
            }
        }
        .info-block {
            box-shadow: $grid-shadow;
            margin-bottom: 15px;
            max-width: 500px;
            min-width: 500px;

            &.extra-padding {
                .info-rows {
                    grid-row-gap:12px;
                }
            }

            .header-text {
                font-family: Arial, Helvetica, sans-serif;
                letter-spacing: 0px;
                text-transform: none;
            }
        }

        &.bor-emails {
            .info-rows {
                grid-template-columns: minmax(0, 90px) minmax(0, 1fr);
            }
        }
    }
}

.panelright {
    margin-right: 0;
    margin-left: 15px;
    overflow-y: auto;

    .subpaneltop,
    .subpanelbottom {
        border: 0;
    }
    .subpaneltop {
        border:1px solid $border-layout;
        height: 50%;
    }
    .subpanelbottom {
        background: $grey-super-light;
        border:1px solid $border-layout;
        height: calc(50% - 25px);
        margin-bottom: 0 !important;

        .changeitem {
            background: $white;
            border: 1px solid $border-grey;
            border-radius: 10px;
            margin: 0 4px;
            padding: 2px 10px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    &.w-buildings {
        .subpaneltop {
            height: 33%;
        }
        .subpanelbottom {
            height: calc(33% - 45px);
        }
    }
    .info-block {
        padding-bottom: 0;
    }
}

.resize-on {
    min-height: 100px;
    resize: vertical;
    overflow: auto;
}