.formula-display {
    text-align: center;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;

    .formula-label {
        color: $grey-primary;
        text-transform: uppercase;
        font-size: 8px;
        max-width: 71px;
        min-height: 20px;
        margin-top: 2px;
    }

    .component {
        position: relative;
        top: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .operator {
        font-size: 1.8rem;
        padding: 0 0.5rem;
    }

    .bracket {
        font-size: 2.8rem;
    }
}
