.override-modal-grid {
    max-width: 500px;

    .sub-header {
        &:nth-child(n+2) {
            margin-left: 2px;
            padding-left: 15px;
        }
    }
}
