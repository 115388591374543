/* GENERAL ADJUSTMENTS */
.no-scroll {
	overflow-y:hidden !important;
}
.pd-rght {
	padding-right:15px;
}
.mrg-btm {
	margin-bottom:30px;
}
.mrg-tp {
	margin-top:30px;
}
.mrg-top-20 {
	margin-top:20px;
}
.frm-lbl {
	color:inherit;
}

.dark-text {
	color: $text-color !important;
}

.mrg-rght {
	margin-right:15px;
}

.flt-rght {
	float:right;
}
h5.no-brd {
	border-bottom:0;
}
legend:nth-child(n+2) {
	margin-top:30px;
}
.cntr {
	margin:auto;
	text-align:center !important;
	justify-content: center;
}
.align-right {
	text-align: right;
}

.bold {
	font-weight: bold;
}

.unbold {
	font-weight: normal;
}

/* COLUMNS */
.column-cnt-2,
.column-cnt-3,
.column-cnt-4 {
	column-width: 300px;
	column-gap: 15px;
	column-fill: balance;
	> * {
		break-inside: avoid;
	}

	&.add-rule {
		column-rule: 1px solid #e0e0e0;
		column-gap: 30px;
	}
}
.column-cnt-2 {
	column-count: 2;
}
.column-cnt-3 {
	column-count: 3;
}
.column-cnt-4 {
	column-count: 4;
}

/* INPUTS with BUTTON ADJUSTMENTS */
fieldset div.inpt-grp.mrg-xtr {
	margin-top:20px;
}
fieldset div.inpt-grp.no-pad {
	padding-right:0;
}
fieldset div.inpt-grp button {
	border-right:0;
}
fieldset div.inpt-grp input {
	flex: 1;
}
fieldset div.inpt-grp .select select {
	border-left:0;
}
/* SELECT FOR SUB FILTERS */
.select.alt {
	display: inline-block;
	margin:0;
	padding-right:0;
	position: relative;
	max-width: 200px;
}
.select.alt select {
	border:0 !important;
	border-collapse: collapse;
	border-radius: 0;
	cursor: pointer;
	display: inline-block;
	outline: 0;
	padding:6px 10px;
	width: 100%;
	appearance: none;
}

.bolder {
	font-weight: 900 !important;
}
