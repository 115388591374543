
.db-label {
	font-weight: 700;
	letter-spacing: 1px;
}

.definitions-table {

	.table-of-contents {
		//> * {
		//	padding: 0.75rem 1rem;
		//}

        h2 {
            a {
                color: $text-color;
            }
        }

        a {
            display: block;
            border-left: 4px solid transparent;
            padding-left: 11px;
            margin-left: -15px;

            &:hover {
                border-color: $blue-primary;
            }
        }

        &.is-current {
            a {
                border-color: $blue-primary;
            }
        }
	}

    h2 {
        font-size: 1.1rem;
        font-weight: bold;
    }

    p, dd {
        margin: 0.25rem 0 0 0;
        font-size: 0.9rem;
        max-width: 800px;
    }

	.toc-section {
		&:not(:first-child) {
			margin-top: 2rem;
		}

		//&:nth-child(even) {
		//	background: #fbfbfb;
		//}
	}

	.toc-table { // dt
		margin: 1.5rem 0 0 0;
		font-size: 0.7rem;
	}

    .table-section {
        .divider-line {
            margin-bottom: 0;
        }
        .description {
            margin-bottom: 1rem;
        }

        h2 {
            padding-top: 1rem;
        }
    }
}

table.glossary-table {
    border: 1px solid $grey-border;
    border-collapse: collapse;

    th {
        background:$grey-super-light;
        border: 1px solid $grey-light;
        border-bottom-color: $grey-medium-light;
        border-top-color: $grey-medium-light;
        font-size:11px;
        letter-spacing:1px;
        text-align:left;
        text-transform:uppercase;

        &:first-child {
            border-left:1px solid $grey-medium-light;
        }

        &:last-child {
            border-right:1px solid $grey-medium-light;
        }
    }

    tr {
        &:nth-child(even) {
            background: #fcfcfc;
        }

        &:last-child td {
            border-bottom-color: $grey-medium-light;
        }
    }

    td {
        white-space: normal;
        word-wrap: break-word;
        border: 1px solid $grey-light;
        border-right: 0;
        font-size: inherit;
        padding: 8px;
        text-align: left;

        &:first-child {
            border-left-color: $grey-medium-light;
        }
        &:last-child {
            border-right-color: $grey-medium-light;
        }
    }
}

	


