.endorsement-pricing-modal {
    h5 {
        flex-grow: 1;
    }

    h4.result-col {
        font-size: 10px;
        min-width: 100px;
        text-align: right;
        width: 100px;
    }

    .input-control {
        width: 200px;
    }

    .formula-display {
        justify-content: center;
    }

    .radio-element-wrapper {
        padding: 0.5rem;
        min-height: 60px;

        .pricing-txt {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &:nth-child(even) {
            background-color: $grey-super-light;
        }
    }

    .price-summary-row {
        align-items: flex-end;
        > * {
            flex-basis: 100%;
        }
    }

    .input_fee_wrapper {
        position: relative;

        .input_current_value {
            right: 30px;
            position: absolute;
        }
    }
}

.viki-ppe-modal {
    table.summary {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }
}

.tiv-icon {
    font-size: 12px;
    position: relative;
    top: 2px;
    transform: rotate(180deg);
}
