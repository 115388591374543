.send-documents-modal {
    width: 1200px;
    padding: 1rem 2rem;

    & > .modal-section {
        margin-bottom: 1rem;
        & > .modal-section-header {
            margin-bottom: 0.5rem;
        }
        & > .modal-section-header > h3 {
            user-select: none;
        }
        & > .modal-section-header > .modal-section-action {
            padding: 0 0 0 0.5rem;
            font-size: 0.6rem;
        }
    }

    & .modal-input-container {
        margin-bottom: 1.25rem;
        & > .modal-input {
            width: 100%;
            border: 1px solid $grey-border;
        }
    }

    & .attachments-container {
        margin-bottom: 2rem;
        & > .attachments-error {
            position: absolute;
            padding-left: 0.5rem;
            font-weight: 700;
        }
    }

    & .attachments-list {
        margin-bottom: 0.5rem;
        max-height: 150px;
        overflow-y: auto;
        & >  div {
            flex: 0 0 24%;
        }
    }

    & .badge {
        display: flex;
        & > span:not(:last-child) {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            white-space: nowrap;
            user-select: none;
        }
    }
}

.send-documents-attachments-tooltip {
    & > span {
        margin-bottom: 0.25rem;
    }
}

.document-selection-tabs {
    margin-bottom: 1rem;
    & > button {
        flex: 1 0 auto;
        font-size: 0.7rem;
    }
}

.document-selection-container {
    height: 300px;
    overflow-y: scroll;
}

.document-selection-table {
    display: grid;
    border-collapse: collapse;
    grid-template-columns: 60px minmax(150px, 2fr) minmax(150px, 2fr) 100px;

    tbody, thead, tr {
        display: contents;
    }

    td, th {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    th {
        position: sticky;
        top: 0;
        background-color: $blue-primary;
        color: white;
        user-select: none;
    }

    & > tbody > tr > td:first-child {
        justify-self: center;
    }

    & > tbody > tr:nth-child(even) > td:not(:first-child) {
        background: #f8f6ff;
    }
}
