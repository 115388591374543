.grid-carriersharespanel {
  display: grid;
  grid-template-columns: 73px 43.5px 77px 70px;
  width: fit-content;
  gap: 0.125rem 0.75rem; 
  align-items: left;
  padding: 0;
  margin-bottom: 0.5rem; 
  text-align: right;
}

.grid-layerpremium {
  display: grid;
  text-align: center;
  gap: 0.125rem 0.75rem; 
  padding: 0;
  align-items: center;
  grid-template-columns: 80px 65px 80px;
  margin-bottom: 0.5rem; 
}