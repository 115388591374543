
/*** PLACED HERE FOR ORDER IMPORTANCE ***/
.select select:hover {
	background:$hover-select;
}

/*** BLUE ***/
/* PRIMARY */
.select select:focus,
.overlay.blue,
td.actn a.icon-pencil:hover {
	background: $blue-primary;
}
/* DARK */
.bkg-blue-dark {
	background: $blue-dark;
}
.actions.alt a span {
	background: #4aced6;
}
.actions.alt a:hover span::before,
.actions.alt a:focus span::before,
.actions.alt a span::before {
	background: #17c2cd;
}

/*** GREY - LIGHT ***/
/* PRIMARY */
.grey-light {
	background: $grey-light;
}
.check-radio input:disabled ~ .check-radio-icon {
	background:rgba(0,0,0,0.1);
}

button.inactive-no-hover,
button.inactive-no-hover:hover {
	background:#fafafa;
}

/*** RED ***/
/* PRIMARY */
.overlay.red,
td.actn a.icon-delete:hover,
.bkg-red {
	background: $red-primary;
}


/*** WHITE ***/
button.inactive,
.select select {
	background:#fff;
}





/******* TEXT *******/
/* BLACK */
.select select,
.nav-primary ul > li > a:hover,
.nav-primary ul > li > a:focus {
	color:#101010;
}

/* GREY - LIGHT */
.grey-light-txt,
button.inactive,
.row.inactive,
div.select .icon-wrapper span,
button.inactive-no-hover,
button.inactive-no-hover:hover,
.notes-wrapper div.notes-item div.message h6 span {
	color: rgba(0,0,0,0.3);
}



/* BLUE */
.blue-txt,
h4,
table thead tr th:hover span,
.nav-primary ul > .active > a:hover,
.notes-wrapper div.notes-item div.message textarea:focus {
	color: $blue-primary;
}

fieldset textarea:focus,
fieldset input:focus,
input:focus,
textarea:focus {
	border-color:$blue-primary;
}

/* WHITE */
.white-txt,
.select select:focus,
.select select:focus .icon-wrapper span,
.checkbox .check-radio-icon:after,
.check-radio input:checked ~ .check-radio-icon,
//table tbody tr td:first-child.active,
//table tbody tr td span.icon-checkmark,
.overlay.red,
.overlay.orange,
td.actn a.icon-delete:hover,
td.actn a.icon-pencil:hover,
#errors {
	color:$white;
}


/****** CURSORS ******/
.overlay div ul li a,
button.inactive,
.crsr-p,
td.actn a {
	cursor:pointer;
}
button.inactive-no-hover {
	cursor:default;
}


/****** DISPLAY ******/
.row.inactive div:nth-child(n+2),
td span.hidden,
.nav-primary ul > li > a span.hidden,
a.hidden {
	display:none;
}

div.logo a span,
.notes-wrapper section,
.notes-wrapper h5 span,
.overlay div ul li a,
.notes-wrapper div.notes-item div.message h6,
td.actn a {
	display:block;
}

.notes-wrapper div.notes-item div.message h6 span {
	display:inline-block;
}

.notes-wrapper div.notes-item {
	display:table;
	width:100%;
}

.notes-wrapper div.notes-item div.message,
.notes-wrapper table tbody tr td {
	vertical-align:top;
}


/**** MARGIN & PADDING OVERRIDE */
.notes-wrapper div.notes-item div.message h6,
.frm-lbl,
.notes-wrapper {
	margin:0;
	padding:0;
}


/* FONT FAMILIES, SIZE & WEIGHTS */

legend,
h4,
.notes-wrapper div.notes-item div.message,
.notes-wrapper div.notes-item div.message h6 {
	font-size:12px;
}

.notes-wrapper h1  {
	font-size:11px;
}

.notes-wrapper div.notes-item div.message h6 span {
	font-size:10px;
}

div.logo a span,
.notes-wrapper div.notes-item div.message h6,
legend,
h4,
h5 {
	 font-weight:700;
}





/* OVERRIDES */
table.summary.edit-mode th,
table.summary.edit-mode th:hover,
table.summary.edit-mode th:hover span {
	background:#AE9137 !important;
	color:#fff !important;
}
table.summary.edit-mode {
	border-color:#AE9137 !important;
}
table.summary.edit-mode td:first-child {
	color:#AE9137 !important;
}

table.summary.edit-mode th span {
	margin-left:10px;
}
