.flex-table {
    .text-cell,
    label {
        font-size: 0.75rem;
    }

    .flex-table-row {
        padding-top: 2px;
        padding-bottom: 2px;

        > * {
            padding-right: 1rem;
            display: flex;
            align-items: center;

            &.input-control {
                justify-content: center;
            }
        }

        > :last-child {
            padding-right: 0;
        }

        &.manuscript-form-row {
            padding: 0.5rem 0;
        }

        .column-list {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
    }

    .input-control {

        .input-wrap {
            border: none;
            &:before,
            &:after {
                border: 1px solid $grey-border;
                border-right:0;
            }
        }

        .input-div {
            &.disabled,
            &:disabled {
                background: $grey-light;
            }
        }
    }

    &.ventus-content {
        padding-right: 0.5rem;
        font-size: 0.8em;

        .section-header {
            margin-top: 1rem;
            font-weight: 600;
        }
        .section-header:first-child {
            margin-top: 0;
        }
    }

    &.even-odd {
        .flex-table-row {
            &:nth-child(even) {
                background: $grey-extra-light;
            }
            &:nth-child(odd):last-child {
                border-bottom: 1px solid $grey-extra-light;
            }
        }
    }

    .subsection {
        padding-top: 1.2rem;
    }
}

.quote-pipeline {
    .input-label {
        font-weight: 700;
    }
}

.pricing-table {
    .input-control {
        padding-right: 1rem;
    }
}
