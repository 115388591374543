.validations-display {
    margin-top: 1rem;
    overflow: auto;
    max-height: 65vh;
}

.quote-errors-list,
.sub-error-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.quote-error:not(:first-child) {
    margin-top: 30px;
}

@mixin panelColor($selector, $color) {
    &.panel-#{$selector} {
        border-color: $color;
        > .panel-heading {
            background: $color;
        }
    }
}

.error-panel {
    margin-bottom: 20px;
    background: $grey-super-light;
    border: 1px solid $grey-super-light;

    .panel-body {
        padding: 20px;

        p {
            margin: 0;
        }

        p + p {
            margin-top: 15px;
        }
    }

    .panel-heading {
        border-radius: 0;
        border: none !important;
        padding: 10px 20px;
    }

    &.panel-default > .panel-heading {
        background-color: #fafafa;
        border-bottom: none;
    }

    .panel-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }
    .panel-sub-title {
        margin-bottom: 3px;
        color: #CEE4FD;
        margin-top: -3px;
    }

    &.panel-color .panel-title {
        color: #ffffff;
    }

    .coverage-option-link {
        font-size: 0.9rem;
    }

    @include panelColor('primary', $blue-primary);
    @include panelColor('warning', $orange-primary);
    @include panelColor('danger', $red-warn);
    @include panelColor('success', #00b19d);
    @include panelColor('info', #3ddcf7);
    @include panelColor('dark', $grey-medium-dark);
}


