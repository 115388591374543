.errors-wrapper {
    h1 {
        color: $red-primary;
        cursor: default;
        font-size: 0.75em;
        letter-spacing: 0.1rem;
        padding-bottom: 5px;
        width: 100%;

        span {
            border-bottom: 2px solid $red-primary;
            color: $red-primary;
            display: inline-block;
            font-family: sans-serif;
            letter-spacing: 2px;
            padding: 3px 5px;
            position: relative;
        }
    }

    .errors-warnings-content {

        table,
        td {
            border: 1px solid $grey-light;
        }

        table {
            background: $white;
            width: 100%;
            border-collapse: collapse;
            margin-top: 6px;
            font-size: 12px;

            thead {
                background: $grey-extra-light;
            }

            td {
                .message {
                    margin: 0;
                    padding: 2px 0;
                }
                .details {
                    color: $grey;
                    padding: 2px 0;
                }
            }
        }
        table tbody tr:hover td {
            background: $grey-extra-light;
        }

        &.warnings {
            padding-left: 0;

            h1 {
                color: $orange-primary;

                span {
                    border-bottom: 2px solid $orange-primary;
                    color: $orange-primary;
                }
            }
        }

        .btn {
            text-transform: uppercase;
            font-size: 11px;
            padding: 1px 3px;
            margin-right: 5px;
            box-shadow: none;
            font-weight: 400;
            border: 1px solid #bbb;
            background: $blue-extra-light;
        }
    }
}
