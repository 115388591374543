button {
	font-family:Helvetica,sans-serif;
	cursor: pointer;
	&:focus:not(:focus-visible) {
		outline: none;
	}
}
.btn {
	border: none;
	font-family: inherit;
	font-size: 0.75em;
	color: inherit;
	background: none;
	display: inline-block;
	margin:0 10px 0 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	position: relative;
	transition: all 0.3s;
}

.btn:after {
	content: '';
	position: absolute;
	z-index: -1;
	transition: all 0.3s;
}
/* Pseudo elements for icons */
.btn:before {
	font-family: 'icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-font-smoothing: grayscale;
}


/* Button 2 */
.btn-2 {
	background: #909090;
	border-radius: 10px;
	color: #fff;
}

.btn-2:hover {
	background: #2d8dc5;
}

.btn-2:active {
	background: #2d8dc5;
}

.btn-2:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 42px;
	font-size: 140%;
	width: 40px;
}

/* Button 2a */
.btn-2a {
	text-align:center;
	padding: 15px 15px 15px 55px;
}

.btn-2a:before {
	background: rgba(0,0,0,0.1);
	border-radius: 10px 0 0 10px;
}

/* Button 2b */
.btn-2b {
	border-radius: 10px;
	padding: 15px 15px 15px 55px;
}

.btn-2b:before {
	border-right: 1px solid rgba(255,255,255,0.5);
}

/* Button 2c */
.btn-2c {
	padding: 55px 15px 15px 15px;
	border-radius: 10px;
}

.btn-2c:before {
	height: 40px;
	width: 100%;
	line-height: 40px;
	background: #e0e0e0;
	color: #fff;
	border-radius: 10px 10px 0 0;
}

.btn-2c:hover:before {
	color: #2d8dc5;
}

/* Button 2d */
.btn-2d {
	padding: 25px 20px 22px 65px;
	border-radius: 10px;
}

.btn-2d:before {
	background: #fff;
	border:2px solid #eaeaea;
	border-right:0;
	color: #909090;
	line-height:58px;
	z-index: 2;
	border-radius: 10px 0 0 10px;
}

.btn-2d:after {
	width: 20px;
	height: 20px;
	background: #fff;
	z-index: 1;
	left: 35px;
	top: 50%;
	margin: -10px 0 0 -10px;
	transform: rotate(45deg);
}

.btn-2d:hover:before {
	color: #2d8dc5;
}

.btn-2d:active {
	top: 0;
}

.btn-2d:hover:after {
	left: 40px;
}
/* Button 2e */
.btn-2e {
	padding: 25px 90px 22px 50px;
	overflow: hidden;
}

.btn-2e:before {
	left: auto;
	top:10px;
	right: 10px;
	z-index: 2;
}

.btn-2e:after {
	width: 30%;
	height: 200%;
	background: rgba(255,255,255,0.1);
	z-index: 1;
	right: 0;
	top: 0;
	margin: -5px 0 0 -5px;
	transform-origin: 0 0;
	transform: rotate(-20deg);
}

.btn-2e:hover:after {
	width: 40%;
}

/* Button 3 */
.btn-3 {
	background:#a0a0a0;
	border-radius: 50%;
	color: #909090;
	height:40px;
	width:40px;
	overflow: hidden;
}
.btn-3:active {
	color: #2d8dc5;
}
.btn-3:hover {
	background: #2d8dc5;
	color:#fff;
}
.btn-3:before {
	position: absolute;
	color: #fff;
}

.btn-3:active:before {
	color: #2d8dc5;
}

/* Button 3a */
.btn-3a:before {
	font-size:140%;
	left: 11.5px;
	top: 12px;
}

.btn-3a:hover:before {
	transform: rotate(360deg) scale(1.25, 1.25);
	transform-origin: 50% 50%;
}

/* Button 4 */
.btn-4 {
	background: #909090;
	color: #fff;
	height: 40px;
	line-height: 40px;
	letter-spacing:2px;
	padding:0 15px;
	overflow: hidden;
	backface-visibility: hidden;
}

.btn-4:active {
	background: #9053a9;
	top: 2px;
}

.btn-4 span {
	display: inline-block;
	width: 100%;
	height: 100%;
	transition: all 0.3s;
	backface-visibility: hidden;
}

.btn-4:before {
	position: absolute;
	height: 100%;
	width: 100%;
	line-height: 60px;
	font-size: 180%;
	transition: all 0.3s;
}

.btn-4:hover {
	background: #2d8dc5;
}

/* Button 4a */
.btn-4a:hover span {
	transform: translateY(200%);
}

.btn-4a:before {
	left: 0;
	top: -100%;
}

.btn-4a:hover:before {
	top: -10px;
}

/* Button 4b */
.btn-4b:hover span {
	transform: translateX(200%);
}

.btn-4b:before {
	left: -100%;
	top: -10px;
}

.btn-4b:hover:before {
	left: 0;
}

.hexagon {
	color:rgba(0,0,0,0.4);
	cursor:pointer;
	display:inline-block;
	position: relative;
	width: 40px;
	height: 23.09px;
	background-color: #2d8dc5;
	margin: 11.55px 0;
	transition: $default-transition;
}
.hexagon span {
	position:absolute;
	top:4px;
	left:11px;
}
.hexagon:hover {
	color:#fff;
}

.hexagon:hover span  {
	transform: scale(1.25, 1.25);
	transform-origin: 50% 50%;
}
.hexagon:before,
.hexagon:after {
	content: "";
	position: absolute;
	width: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
}

.hexagon:before {
	bottom: 100%;
	border-bottom: 11.55px solid #2d8dc5;
}

.hexagon:after {
	top: 100%;
	width: 0;
	border-top: 11.55px solid #2d8dc5;
}
