/* NAVIGATION */
/* PRIMARY */
.nav-primary ul {
	margin:0;
	padding:0;
}
.nav-primary ul > li {
	list-style:none;
	text-decoration:none;
}

.nav-primary ul > li > a span {
	display:block;
	font-size:10px;
	margin:4px 0 0 0;
}

/* NESTED LINKS  */
.nav-primary ul ul > li > a {

}


.nav-primary li label {
	color: #808080;
	display:block;
	font-size:10px;
	font-weight:700;
	letter-spacing:1px;
	padding:8px 0 0px 45px;
	text-transform:uppercase;
	width:100%;
}
.nav-primary li label.brd {
	border-top:1px solid #e0e0e0;
	margin-top:10px;
	padding-top:12px;
}

/* MARGING & PADDING ADJUSTMENTS FOR FIRST AND LAST ELEMENTS  */
.nav-primary ul ul > li:first-child > a {
	padding-top:4px;
}
.nav-primary ul ul ul > li:first-child > a {
	padding-top:8px;
}
.nav-primary ul ul > li:last-child > a {
	margin-bottom:10px;
}
.nav-primary ul ul ul > li:last-child > a {
	margin-bottom:0px;
}

/* THIRD LEVEL - NESTED LINKS  */
.nav-primary ul ul ul > li > a {
	font-size: 11px;
	padding:10px 0 0 50px;
}

/* FOURTH LEVEL - NESTED LINKS  */
.nav-primary ul ul ul ul > li > a {
	font-size: 11px;
	font-weight:500;
	padding:8px 0 0 65px;
}

/* ACTIVE & HOVER */
.nav-primary ul > .active > a,
.nav-primary ul > li > a:hover,
.nav-primary ul > li > a:focus {
	background-color: transparent;
	text-decoration: none;
}

/* NESTED - ACTIVE */
.nav-primary ul ul > .active > a, .nav-primary ul ul > .active:hover > a,
.nav-primary ul ul > .active:focus > a {
}

/* HIDE INACTIVE */
.nav-primary ul ul {
	display: none;
}
/* DISPLAY ACTIVE */
.nav-primary .active {
	display: block;
}

.notes-users span {
	background:#39aa6e;
	border:1px solid #39aa6e;
	border-radius:50%;
	display:inline-block !important;
	height:10px;
	position:relative;
		top:0px;
	width:10px;
}
.notes-users span.offline {
	background:#f0f0f0;
	border-color:#e0e0e0;
}

