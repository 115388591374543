
$tableBorder: 1px solid $grey-light;

table {
    font-size: 0.8rem;
    border-spacing: 0;

    &.summary {
        width: 100%;
        border: $tableBorder;

        thead {
            th {
                border-bottom: $tableBorder;

                &:not(:first-child) {
                    border-left: $tableBorder;
                }
            }
        }

    }
}

th,
td {
    text-align: left;
    padding: 0.25rem 0.5rem;

    &.num {
        text-align: right;
    }
}


.num {
    text-align: right;
    justify-content: flex-end;
}

tr.headers {
    color: $grey;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 6px;      
}


.spaced {
    margin-bottom: 10px;
}