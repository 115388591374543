fieldset,
.tbl-buttons {
    button:not(.no-viki-style),
    input:not(.no-viki-style) {
        border: 1px solid #d0d0d0;
        border-collapse: collapse;
        font-size: inherit;
        margin: 0;
        padding: 6px 10px;
    }

    button:not(.no-viki-style) {
        font-weight: 600;
        background: $grey-primary;

        &:hover {
            background: $grey-dark;
        }

        &.active {
            background: $blue-primary;

            &:hover {
                background: darken($blue-primary, 10%);
            }
        }

        &:disabled {
            background: $grey-disabled;
            cursor: default;
        }

        &:nth-child(n + 2) {
            border-left: 0;
        }
    }

    input:not(.no-viki-style) {
        &.brd-lft {
            border-left: 1px solid #d0d0d0;
        }
    }
}

.txt-area-wrapper {
    padding-right: 15px;
}
fieldset textarea {
    border: 1px solid #d0d0d0;
    font-size: inherit;
    margin: 0;
    min-height: 80px;
    outline: none;
    padding: 8px 10px;
    resize: none;
    width: 100%;
}
fieldset textarea:focus,
fieldset input:focus,
input:focus,
textarea:focus {
    outline: none;
}

/* STYLE for CHECKED ELEMENT - RADIO / CHECKBOX */


/* SELECT CUSTOM */
.select {
    display: inline-block;
    margin: 0;
    padding-right: 15px;
    position: relative;
    width: 100%;
}
.select select {
    border: 1px solid #d0d0d0;
    border-collapse: collapse;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    outline: 0;
    padding: 6px 10px;
    width: 100%;
    appearance: none;
}

.select select::-ms-expand {
    display: none;
}
div.select .icon-wrapper {
    position: absolute;
    top: 8px;
    right: 40px;
    width: 0;
    height: 0;
    pointer-events: none;
}



.raw-div-input {
    padding: 2px 8px;
    > .input-row {
        width: 100%;
    }

    .raw-div-input-wrap {
        flex-direction: column;
    }

    .raw-div-input-row {
        padding: 0 1px;
    }
}
