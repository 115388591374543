
@mixin rowSelectorColor($color) {
  .row-selector {
    background-color: $color !important;
    .icon-checkmark {
      color: white !important;
    }
  }
}


.clearance-editor-app {
  padding: 0;

  .row-selector {
    background-color: transparent !important;
  }

  .clearance-processed {

  }
  .clearance-declined {
    @include rowSelectorColor($red-primary);
  }
  .clearance-deleted {
    @include rowSelectorColor($red-dark);
  }
  .clearance-inbox {

  }
  .clearance-cleared {
    @include rowSelectorColor($green-primary);
  }
  .clearance-sent {
    @include rowSelectorColor($blue-primary);
  }
}

.ventus-wrapper.hlf {
  width:50%;
  left:0;
  top:0;
}
.ventus-wrapper.hlf.rght {
  border-left:1px solid $border-layout;
  left:50%;
  overflow:hidden;
}

.ventus-wrapper.hlf {
  .ventus-content  {
    background:$white;

    ul,
    p {
      font-size:12px;
      list-style:none;
      margin:8px;
      padding:0;

      li  {
        padding:2px 0;
      }
    }
  }

  .ventus-content.tp  {
    height:80%;
    position:absolute;
    top:0;
  }
  .ventus-content.tp.sml  {
    height:50%;
  }
  .ventus-content.btm  {
    box-shadow:1px -5px 30px rgba(0,0,0,0.08);
    border-top:1px solid $border-layout;
    height: 20%;
    position:absolute;
    bottom: 0;
  }
  .ventus-content.btm.open  {
    height:50%;
  }

  #email-expand-btn.hidden,
  #email-close-btn {
    display:none;
  }
  #email-close-btn.open {
    display:block;
  }
}

.submission-filters.clearance {
  margin:0 0 15px 0 !important;
}

.table-key {

  display: flex;
  font-weight: 700;

  .key-item {

    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 15px;
    }

    .key-figure {
      margin-left: 4px;
      content:'';
      display: inline-block;
      height: 10px;
      width: 10px;
      border:1px solid $grey-disabled;
    }

    &.clear .key-figure {
      background:$green-primary;
      border-color:$green-primary;
    }
    &.decline .key-figure {
      background:$red-primary;
      border-color:$red-primary;
    }
    &.reply .key-figure {
      background:$blue-primary;
      border-color:$blue-primary;
    }
    &.nothing .key-figure {
    }
  }
}
