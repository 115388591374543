.multgrid-wrapper {
    margin-top: 15px;
}

.return-period-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.header-stats {
    color: $black;
    font-family: sans-serif;
    float:right;
    letter-spacing: 0;
    max-width: 300px;

    &.alt {
        border: 1px solid $border-grey;
        font-size: 12px;
    }

    label {
        font-size: 80%;
        font-weight:bold;
        text-transform: uppercase;
    }
    span {
        font-weight:100;
    }

    .adjustments {
        border-left: 1px solid $border-grey;
        margin-left: 10px;
        padding-left: 10px;
    }
}