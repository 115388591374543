.progress-bar-wrapper {
    width: 50%;

    h1 {
        color: $blue-primary;
        font-size: 0.65rem;
        letter-spacing: 2px;
        margin-left: 0px;

        span {
            display: inline-block;
        }

        .success {
            border: 2px solid $green-primary;
            color: $green-primary;
            font-size: 90%;
            letter-spacing: 2px;
            padding: 2px 4px;
            text-transform: uppercase;
        }
        .fail {
            border: 2px solid $red-primary;
            color: $red-primary;
            font-size: 90%;
            letter-spacing: 2px;
            padding: 2px 4px;
            text-transform: uppercase;
        }
    }
}

.progress-bar {
    background:$transparent-10;
    padding: 0;
    position: relative;

    .percent {
        color:$white;
        font-size:9px;
        font-weight:900;
        position: absolute;
        left: 2%;
        top:1px;
    }

    .bar {
        height: 12px;
        background-color: $blue-primary;
        width: 30%;
    }
}
