div.loading-status {
	background: $grey-extra-light;
	border:1px solid $border-layout;
	border-top:0;
	color:$white;
	height: auto;
	margin:0;
	padding:4px 0px;
	position: fixed;
		left:15%;
		right:0;
		top:-100%;
	width:70%;
	z-index:999999;

	&.active {
		top:59px;

		box-shadow:6px 2px 20px 0px rgba(0,0,0,0.1);
	}
	.container {
		display:table;
		height:100%;
		width:100%;
	}
}
