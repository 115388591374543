.viki-404-page {
    & > h2 {
        font-size: 60px;
        font-weight: 600;
        color: $blue-primary;
    }

    & > p {
        font-size: 32px;
    }
}
