.center-self {
    align-self: center;
}

label {
    cursor: inherit;
}

.center {
    text-align: center;
}

.invisible {
    visibility: hidden;
    pointer-events: none;
}

.flash-message {
    opacity: 0;
    transition: opacity 0.8s ease;

    &.flash {
        opacity: 1;
        transition: opacity 0.2s ease; // Fades out slowly
    }
}

.flex-grow {
    flex-grow: 1;
}

.viki-cell.pdk {
    font-style: italic;
}

a.link {
    color: $blue-primary;
    &:hover {
        color: $blue-bright;
        text-decoration: underline;
    }
}

.fs-08rem {
    font-size: 0.8rem;
}

.fs-1rem {
    font-size: 1rem;
}

.fs-15rem {
    font-size: 1.5rem;
}

.no-display {
    display: none !important;
}
.aspect {
    position: relative;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    &.aspect-4-3 {
        padding-bottom: 75%;
    }
}

.ellipsize,
.ellipsize * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.panel-page {
    display: flex;
    padding: 1rem;
}

.price-summary-txt {
    text-align: center;

    .pricing-txt {
        font-size: 1rem;
        padding: 0.5rem 0 0;
    }

    h6 {
        font-size: 0.8rem;
        letter-spacing: 0.1rem;
        margin: 0;
        text-transform: uppercase;
        color: $grey-dark;
    }
}

.pricing-txt {
    font-family: sans-serif;
    letter-spacing: 0.1em;
    font-weight: 700;

    &.negative {
        color: $red-primary !important;
    }

    &.positive {
        color: $green-primary !important;
    }
}

// TODO: change css is jslib
.bottom-left-grid {
    border-right: none !important;
}
