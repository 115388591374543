@import '@archinsurance-viki/property-jslib/src/styles/common/constants.scss';



.total-premium-summary {
    .flex-table-row {
        min-height: 2rem;
    }
}

.pricing-wrapper {

    .expiring-tab-loading-icon {
        margin-left: 50%;
        margin-top: 10%;
    }


    .pee-override-applied * {
        color: $blue-bright !important;
    }

    .handle {
        &.active {
            .text {
                color: $blue-bright !important;
            }
        }
    }
    .input-div {
        padding: 0 !important;
        max-width: 100px;
        justify-self: flex-end;

        input {
            padding: 2px;
        }

        .info-block {
            max-width: 350px;
        }
    }

    hr {
        border-top: 1px solid $grey-light;
    }

    .header,
    .sub-header {
        font-family: sans-serif !important;
        letter-spacing: 0 !important;
    }

    .sub-panel {
        margin: 0 0 15px 0;

        .pricing {
            grid-template-columns: auto;

            .info-block {
                .grid-layout {
                    label {
                        padding-right: 40px;
                    }
                }
            }
        }
    }
}
.pricing-header {
    grid-template-columns: 1fr max-content;

    .grid-layout {
        font-size: 0.75rem;

        div {
            text-align: right;
        }
        label {
            min-width: max-content;
        }
    }
}

.secondary-content {
    background: $white;
    border-top: 1px solid $border-grey;
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 15px;
    max-height: 385px;

    &.active {
        display: block;
    }

    .pricing {
        grid-template-columns: 1fr 1fr;
    }
}


.pricing-side-by-side {
    left: 638px;
    height: calc(100vh - 250px);
}

.pricing-row-height {
    margin: 1px 0;
    max-height: 20px;
    min-height: 20px;
}
.pricing-header-spacer {
    max-height: 14px;
    min-height: 14px;
}